import React, {Component} from 'react';
import DeleteEvent from '../components/DeleteEvent';
import ImportCsv from '../components/ImportCsv';
// import ShowInscription from '../components/ShowInscription';
import ResellersShowRaces from '../components/ResellersShowRaces';
import Message from '../components/Message';
import Header from '../components/Header';
import cookie from 'react-cookies';

class Resellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      tokenId: '',
      interpretedAlert: null,
      showDeclarative: false
    };
  }

  componentDidMount() {
    fetch("https://api.goaltiming.be/events?type=all")
      .then(res => res.json())
      .then(
        (result) => {
            const sortedEvents = result.sort((a, b) => a.date - b.date);
            const selectedEvents = [];

            for (var i = 0; i<(result.length); i++) {
              var eventDate = this.convertDate(sortedEvents[i].date);
              var publicDate = this.convertDate(sortedEvents[i].publicationDate);

              // if(cookies.selectedRun && sortedEvents[i].EventId == cookies.selectedRun){
              if(sortedEvents[i].EventId == 'adf1e970-cb71-11ef-8926-95d561183b75'){
                  selectedEvents.push({
                  name : sortedEvents[i].name,
                  date : eventDate,
                  publicationDate : publicDate,
                  banner : sortedEvents[i].banner,
                  id : sortedEvents[i].EventId
                });
              }
            }

          this.setState({
            isLoaded: true,
            items: selectedEvents
          });

        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      let cookies = cookie.loadAll();

      if(!cookies.tokenId){
          let d = new Date();
          var url = window.location.href;
          var url = window.location.hash;
          var selected = url.split('id_token' + '=');

          if(selected[1] !== undefined){
              var value = selected[1].split('&');
              let tid = value[0];
              
              d.setTime(d.getTime() + (55*60*1000));
              cookie.save("tokenId", tid, {path: "/", expires: d});

              this.setState({tokenId: tid});
          }else{
            window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
          }
      }else{
          this.setState({tokenId: cookies.tokenId});
      }
  }

  convertDate(d){
    var date = new Date(d*1000);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  render() {
    const { error, isLoaded, items, tokenId } = this.state;
    const action = this.props.match.params.action;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded || tokenId === '') {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>
            {/* [ Main Content ] start */}
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing - Revendeurs</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <div className="main-body">     
                                <div className="page-wrapper">
                                    {/* [ Main Content ] start */}
                                    <div className="row">
                                        {/* [ stiped-table ] start */}
                                        <div className="col-xl-12">
                                            <div className="card">
                                              
                                                <Message datamesage={action} />
                                                <div className="card-block table-border-style">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Titre</th>
                                                                    <th>Date de l'évènement</th>
                                                                    <th>Date de publication</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.map(function(item, i){
                                                                   return <tr key={i}>
                                                                            <th scope="row">{i+1}</th>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>{item.publicationDate}</td>
                                                                            <td>
                                                                              <ResellersShowRaces dataid={item.id} token={tokenId} />
                                                                            </td>
                                                                          </tr>;
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* [ stiped-table ] end */}
                                    </div>
                                    {/* [ Main Content ] end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
      );
    }
  }
}

export default Resellers