import React from 'react';
import useForm from 'react-hook-form';

let backupItem = [];
let backupCustoms = [];

export default function FormRegistered(props) {
    const { register, handleSubmit, errors } = useForm();
    const eventid = props.eventid;
    const raceid = props.raceid;
    const registered = props.registered || {items: [], customFields: []};
    const items = props.items;
    const type = props.type;
    const customfields = props.customfields || [];

    for(var i=0; i<customfields.length; i++){
        if(registered.customFields[i] === null || registered.customFields[i] === undefined){
            registered.customFields[i] = {};
            registered.customFields[i].name = customfields[i].label;
            registered.customFields[i].value = '';
        }else{
            backupCustoms[i] = registered.customFields[i];
        }
    }

    for(var i=0; i<items.length; i++){
        if(registered.items[i] === null || registered.items[i] === undefined){
            registered.items[i] = {};
            registered.items[i].name = items[i].name;
            registered.items[i].price = items[i].price;
            registered.items[i].number = 0;
            registered.items[i].totalprice = 0;
        }
    }

    const onSubmit = function(d){
        registered.dossard_nbr = d.dossard_nbr;

        var xhr = new XMLHttpRequest()
            xhr.open('PUT', "https://api.goaltiming.be/participants/" + registered.ParticipantId + "/events/" + eventid + '/races/' + raceid)
            xhr.setRequestHeader('Authorization', props.token)
            xhr.send(JSON.stringify(registered))
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    window.location.replace('/resellers/registered/'+ eventid +'/races/'+ raceid +'/edit');
                }
            }
        
    }

    const convertToDate = function(date){
        if(date){
            if(date.includes('/')){
                var split = date.split('/');
                return split[2] + '-' + split[1] + '-' + split[0];
            }else{
                return date;
            }
        }
    }

    return (
        <form className="main-body" onSubmit={handleSubmit(onSubmit)}>
            <div className="page-wrapper">
                {/* [ Main Content ] start */}
                <div className="row">
                    <div className="col-sm-12">

                        {/* [Infos] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Informations sur le participant</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom *</label>
                                            <input readOnly type="text" className="form-control" name="name" defaultValue={registered.name} placeholder="Nom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Email *</label>
                                            <input readOnly type="text" className="form-control" name="email" placeholder="email@email.be" defaultValue={registered.email} ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
                                            <small className="form-text form-error">{errors.email && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom *</label>
                                            <input readOnly type="text" className="form-control" name="firstname" defaultValue={registered.firstname} placeholder="Prénom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Dossard</label>
                                            <input type="text" className="form-control" name="dossard_nbr" placeholder="dossard" defaultValue={registered.dossard_nbr} ref={register} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* [Publication] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Information de publication</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button className="btn btn-primary" type="submit">Enregistrer</button>
                                        </div>
                                    </div>                                                       
                                </div>
                            </div>
                        </div>
                        {/* [Publication] END */}

                    </div>
                </div>
                {/* [ Main Content ] end */}
            </div>
        </form>
    );
}