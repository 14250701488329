import React, {Component} from 'react';
import Header from '../components/Header';
import cookie from 'react-cookies';
import ResellersFormRegistered from '../components/ResellersFormRegistered';

class ResellersRegisteredCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      registered: {},
      name: '',
      raceName: '',
      items: [],
      token: '',
      customFields: []
    };
  }

  componentDidMount() {
    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
        window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
    }else{
        fetch("https://api.goaltiming.be/events/" + this.props.match.params.eventid + '/admin/',{
        headers: new Headers({
            'Authorization': cookies.tokenId
        }), 
        })
        .then(res => res.json())
        .then(
            (event) => {
                // GET EVENT DATAS
                for(var i =0; i<event.races.length; i++){
                    if(event.races[i].id === this.props.match.params.raceid){
                        this.setState({
                            raceName: event.races[i].name,
                            customFields: event.races[i].customFields
                        });
                    }
                }

                this.setState({
                    isLoaded: true,
                    name: event.name,
                    items: event.items,
                    token: cookies.tokenId
                });
                
            },(error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }
  }

  render() {
    const { error, isLoaded } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>

            {/* [ Main Content ] start */}
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing : Créer un participant</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/resellers/view"><i className="feather icon-home"></i></a></li>
                                                <li className="breadcrumb-item"><a href={'/resellers/registered/'+this.props.match.params.eventid+'/races/'+this.props.match.params.raceid+'/create'}>{this.state.name + ' : ' + this.state.raceName}</a></li>
                                                <li className="breadcrumb-item"><a>Création</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <ResellersFormRegistered eventid={this.props.match.params.eventid} raceid={this.props.match.params.raceid} items={this.state.items} customfields={this.state.customFields} token={this.state.token} type="create" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
      );
    }
  }
}

export default ResellersRegisteredCreate