import React from 'react';
import useForm from 'react-hook-form';

let backupItem = [];
let backupCustoms = [];

export default function FormRegistered(props) {
    const { register, handleSubmit, errors } = useForm();
    const eventid = props.eventid;
    const raceid = props.raceid;
    const registered = props.registered || {items: [], customFields: []};
    const items = props.items;
    const type = props.type;
    const customfields = props.customfields || [];

    for(var i=0; i<customfields.length; i++){
        if(registered.customFields[i] === null || registered.customFields[i] === undefined){
            registered.customFields[i] = {};
            registered.customFields[i].name = customfields[i].label;
            registered.customFields[i].value = '';
        }else{
            backupCustoms[i] = registered.customFields[i];
        }
    }

    for(var i=0; i<items.length; i++){
        if(registered.items[i] === null || registered.items[i] === undefined){
            registered.items[i] = {};
            registered.items[i].name = items[i].name;
            registered.items[i].price = items[i].price;
            registered.items[i].number = 0;
            registered.items[i].totalprice = 0;
        }
    }

    const onSubmit = function(d){
        if(d.sex !== ""){
            registered.sex = d.sex;
        }
        registered.name = d.name;
        registered.firstname = d.firstname;
        registered.email = d.email;
        registered.dob = d.dob;
        registered.address = d.address;
        registered.country = d.country;
        registered.club = d.club;
        registered.items = backupItem;
        registered.customFields = backupCustoms;
        registered.dossard_nbr = d.dossard_nbr;
        registered.note = d.note;

        if(type === 'create'){
            var date = new Date();
            var timestamp = date.getTime();

            registered.accept_rgpd = true;
            registered.accept_newsletter = false;
            registered.accept_rules = true;
            registered.is_in_good_health = true;
            registered.payement_status = 'success';
            registered.payement = {
                date: timestamp/1000,
                mode: "Created by admin",
                promoCode: false
            };

            var xhr = new XMLHttpRequest()
                xhr.open('POST', "https://api.goaltiming.be/participants/events/" + eventid + '/races/' + raceid)
                xhr.setRequestHeader('Authorization', props.token)
                xhr.send(JSON.stringify(registered))
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        window.location.replace('/registered/'+ eventid +'/races/'+ raceid +'/created');
                    }
                }
        }else{
            var xhr = new XMLHttpRequest()
                xhr.open('PUT', "https://api.goaltiming.be/participants/" + registered.ParticipantId + "/events/" + eventid + '/races/' + raceid)
                xhr.setRequestHeader('Authorization', props.token)
                xhr.send(JSON.stringify(registered))
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        window.location.replace('/registered/'+ eventid +'/races/'+ raceid +'/edit');
                    }
                }
        }
        
    }
    
    const handleChange = function(e){
        e.preventDefault();

        if(e.target.attributes.datatype.nodeValue === 'custom'){
            backupCustoms[e.target.attributes.datakey.nodeValue] = {
                name: customfields[e.target.attributes.datakey.nodeValue].label,
                value: e.target.value,
            };
        }else{
            if(e.target.value < 0){
                e.target.value = 0;
            }
    
            backupItem[e.target.attributes.datakey.nodeValue] = {
                number: Math.round(e.target.value),
                name: e.target.attributes.dataname.nodeValue,
                price: e.target.attributes.dataprice.nodeValue,
                totalprice: e.target.value*e.target.attributes.dataprice.nodeValue
            };
        }
    }

    const convertToDate = function(date){
        if(date){
            if(date.includes('/')){
                var split = date.split('/');
                return split[2] + '-' + split[1] + '-' + split[0];
            }else{
                return date;
            }
        }
    }

    return (
        <form className="main-body" onSubmit={handleSubmit(onSubmit)}>
            <div className="page-wrapper">
                {/* [ Main Content ] start */}
                <div className="row">
                    <div className="col-sm-12">

                        {/* [Infos] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Informations sur le participant</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom *</label>
                                            <input type="text" className="form-control" name="name" defaultValue={registered.name} placeholder="Nom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Sexe *</label>
                                            <div className="row">
                                                <div className="col"><input type="radio" name="sex" value="F" defaultValue={props.sexF} ref={register} /> Femme</div>
                                                <div className="col"><input type="radio" name="sex" value="H" defaultValue={props.sexH} ref={register} /> Homme</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom *</label>
                                            <input type="text" className="form-control" name="firstname" defaultValue={registered.firstname} placeholder="Prénom" ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis</span>}</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Date de naissance *</label>
                                            <input type="date" className="form-control" name="dob" defaultValue={convertToDate(registered.dob)} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.dob && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email *</label>
                                            <input type="text" className="form-control" name="email" placeholder="email@email.be" defaultValue={registered.email} ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
                                            <small className="form-text form-error">{errors.email && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Adresse *</label>
                                            <input type="text" className="form-control" name="address" placeholder="Adresse..." defaultValue={registered.address} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.address && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Pays *</label>
                                            <input type="text" className="form-control" name="country" placeholder="Pays" defaultValue={registered.country} ref={register({required: true})} />
                                            <small className="form-text form-error">{errors.country && <span>Ce champ est requis</span>}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Equipe</label>
                                            <input type="text" className="form-control" name="club" placeholder="club" defaultValue={registered.club} ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Dossard</label>
                                            <input type="text" className="form-control" name="dossard_nbr" placeholder="dossard" defaultValue={registered.dossard_nbr} ref={register} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <input type="text" className="form-control" name="note" placeholder="Notes..." defaultValue={registered.note} ref={register} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Infos] END */}

                        {/* [Item] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Les objets</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                    {registered.items.map((item, i) =>
                                        <div key={i}>
                                            {item.name} ({item.price} €) <input type="number" step="1" min="0" dataname={item.name} datakey={i} dataprice={item.price}  defaultValue={item.number} className="form-control" datatype="item" onChange={handleChange} />
                                            <hr />
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Items] END */}

                        {/* [Customs] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Les customs</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                    {customfields.map((field, i) =>
                                        <div key={i}>
                                            {field.label} <input type="text" datakey={i} defaultValue={registered.customFields[i].value} datatype="custom" className="form-control" onChange={handleChange} />
                                            <hr />
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* [Customs] END */}

                        {/* [Publication] START */}
                        <div className="card">
                            <div className="card-header">
                                <h5>Information de publication</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button className="btn btn-primary" type="submit">Enregistrer</button>
                                        </div>
                                    </div>                                                       
                                </div>
                            </div>
                        </div>
                        {/* [Publication] END */}

                    </div>
                </div>
                {/* [ Main Content ] end */}
            </div>
        </form>
    );
}