import React, {Component} from 'react';
import DeleteChallenger from '../components/DeleteChallenger';
import Message from '../components/Message';
import Header from '../components/Header';
import cookie from 'react-cookies';

class Challenger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      challengeName: '',
      sectionName: '',
      interpretedAlert: null,
      showDeclarative: false,
      challengeId: this.props.match.params.challengeid,
      sectionId: this.props.match.params.sectionid,
      tokenId: '',
      challengersUpdate: {}
    };
  }

  componentDidMount() {

    let cookies = cookie.loadAll();

    if(!cookies.tokenId){
        window.location.replace("https://auth.goaltiming.be/login?client_id=1n014khs8r0ghlvuvg7vds0tbg&response_type=token&scope=email%20openid&redirect_uri=https%3A%2F%2Fadmin.goaltiming.be%2Fview&fbclid=IwAR02CuBWOwLypoSCXUZ_MPuHVGTK7KUyg2cmEOA1JDsrUZgM8KnM36PV6gA");
    }else{
        const [header, payload, signature] = cookies.tokenId.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        if(decodedPayload.email == 'revendeur.goaltiming@gmail.com'){
          window.location.replace("/resellers/view");
        }
        console.log(this.props.match.params)
        fetch("https://api.goaltiming.be/challengers/challenges/"+this.props.match.params.challengeid+"/sections/"+this.props.match.params.sectionid, {
            method: 'GET',
            headers: new Headers({
                'Authorization': cookies.tokenId
            }), 
        })
        .then(res => res.json())
        .then(
        (result) => {
            fetch("https://api.goaltiming.be/challenges/" + this.props.match.params.challengeid+ '/admin/',{
            headers: new Headers({
                'Authorization': cookies.tokenId
            }), 
            })
            .then(res => res.json())
            .then(
                (challenge) => {
                    // CREATE REGISTERED LIST
                    const sortedChallenger = result.sort((a, b) => a.date - b.date);
                    const selectedChallenger = [];

                    for (var i = 0; i<(result.length); i++) {
                        var creationDate = this.convertDate(sortedChallenger[i].creationDate);

                        if(sortedChallenger[i].payement_status == 'success' || sortedChallenger[i].payement.mode == 'free'){
                            selectedChallenger.push({
                                name : sortedChallenger[i].name + ' ' + sortedChallenger[i].firstname,
                                email: sortedChallenger[i].email,
                                dossard: sortedChallenger[i].dossard,
                                date : creationDate,
                                id : sortedChallenger[i].ChallengerId
                            });
                        }
                    }

                    // GET EVENT DATAS
                    for(var i =0; i<challenge.sections.length; i++){
                        if(challenge.sections[i].id === this.props.match.params.sectionid){
                            this.setState({sectionName: challenge.sections[i].name});
                        }
                    }

                    // let challengeId = this.props.match.params.challengeid
                    // let sectionId = this.props.match.params.sectionid
                    // for(i=0;i<selectedChallenger.length;i++){
                    //     var base = btoa(selectedChallenger[i].id)

                    //     var xhr = new XMLHttpRequest()
                    //         xhr.open('PATCH', "https://api.goaltiming.be/challengers/" + selectedChallenger[i].id + "/challenges/" + challengeId + '/sections/' + sectionId)
                    //         xhr.setRequestHeader('Authorization', cookies.tokenId)
                    //         xhr.send(JSON.stringify({identifier : base}))
                    //         xhr.onreadystatechange = function() {}
                    // }

                    // SET DATAS
                    this.setState({
                        isLoaded: true,
                        challengeName: challenge.name,
                        challenger: selectedChallenger,
                        tokenId: cookies.tokenId
                    });
                    
                },(error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            
            },(error) => {
            this.setState({
                isLoaded: true,
                error
            });
            }
        )
    }
  }
  
  updateChallengers(id, value){
    let update = this.state.challengersUpdate
    update[id] = value

    this.setState({
        challengersUpdate: update
    })
  }

  saveChallengers(e){
    e.preventDefault()

    let L = Object.keys(this.state.challengersUpdate).length
    let i = 1
    let challengeId = this.props.match.params.challengeid
    let sectionId = this.props.match.params.sectionid

    for (const [key, value] of Object.entries(this.state.challengersUpdate)) {

        if(i < L){
            var xhr = new XMLHttpRequest()
                xhr.open('PATCH', "https://api.goaltiming.be/challengers/" + key + "/challenges/" + challengeId + '/sections/' + sectionId)
                xhr.setRequestHeader('Authorization', this.state.tokenId)
                xhr.send(JSON.stringify({dossard: value}))
                xhr.onreadystatechange = function() {}
        }else{
            var xhr = new XMLHttpRequest()
                xhr.open('PATCH', "https://api.goaltiming.be/challengers/" + key + "/challenges/" + challengeId + '/sections/' + sectionId)
                xhr.setRequestHeader('Authorization', this.state.tokenId)
                xhr.send(JSON.stringify({dossard: value}))
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        window.location.replace('/challenger/'+ challengeId +'/sections/'+ sectionId +'/edit');
                    }
                }
        }
        i++
    }
  }

  convertDate(d){
    var date = new Date(d*1000);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  render() {
    const { error, isLoaded, challenger, tokenId, challengeId, sectionId } = this.state;
    const action = this.props.match.params.action;
    const _this = this;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded || tokenId === '') {
      return <div className="loader">Loading...</div>;
    } else {
      return (

        <div>
            {/* [ Header ] start */}
              <Header />
            {/* [ Header ] end */}

            {/* [ Main Content ] start */}
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            {/* [ breadcrumb ] start */}
                            <div className="page-header">
                                <div className="page-block">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="page-header-title">
                                                <h5 className="m-b-10">Goal Timing - {this.state.challengeName}</h5>
                                            </div>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/view"><i className="feather icon-home"></i></a></li>
                                                <li className="breadcrumb-item"><a href="/challenges/view">Challenges</a></li>
                                                <li className="breadcrumb-item"><a>Challengers</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* [ breadcrumb ] end */}
                            <div className="main-body">     
                                <div className="page-wrapper">
                                    {/* [ Main Content ] start */}
                                    <div className="row">
                                        {/* [ stiped-table ] start */}
                                        <div className="col-xl-12">
                                            <div className="card">
                                              
                                                <Message datamesage={action} />

                                                <div className="card-header">
                                                    <h5>Section : {this.state.sectionName}</h5>
                                                    <span className="d-block m-t-5"><a href={'/challengers/challenges/'+this.props.match.params.challengeid+'/sections/'+this.props.match.params.sectionid}>+ ajouter un challenger  </a></span>
                                                </div>
                                                <div className="card-block table-border-style">
                                                    <form className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Dossard n°</th>
                                                                    <th>Nom</th>
                                                                    <th>Email</th>
                                                                    <th>Date d'inscription</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {challenger.map(function(item, i){
                                                                   return <tr key={i}>
                                                                            <th scope="row">{i+1}</th>
                                                                            <td>
                                                                                {item.dossard != '0' && item.dossard ? (
                                                                                    <span>{item.dossard}</span>
                                                                                ) : (
                                                                                    <span>
                                                                                        <input type="text" className="form-control" name="dossard" onChange={e => _this.updateChallengers(item.id, e.target.value)} required />
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>
                                                                                <a href={'/challengers/'+item.id+'/challenges/'+challengeId+'/sections/' + sectionId}>Editer</a>
                                                                                <DeleteChallenger challengerid={item.id} challengeid={challengeId} sectionid={sectionId} name={item.name} token={tokenId} />
                                                                            </td>
                                                                          </tr>;
                                                                })}
                                                            </tbody>
                                                        </table>

                                                        <button className="btn btn-primary" onClick={e => this.saveChallengers(e)}>Enregistrer les dossards encodés</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* [ stiped-table ] end */}
                                    </div>
                                    {/* [ Main Content ] end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
      );
    }
  }
}

export default Challenger