import React, {Component} from 'react';

class ResellersShowRaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            imgurl: '',
            races: [],
            id: this.props.dataid
        };
        this.showRaces = this.showRaces.bind(this);
    }

    showRaces() {
        fetch("https://api.goaltiming.be/events/" + this.state.id + '/admin/',{   
          headers: new Headers({
            'Authorization': this.props.token
          }), 
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({races: result.races});
            },(error) => {
                console.log(error);
            }
        )
    }

    handleChange(e){
        e.preventDefault();
        console.log(e.target);
    }

    render() {
        const { error, races, id } = this.state;

        if (error) {
        return <div>Erreur : {error.message}</div>;
        } else {
            return (
                <div>
                    <button onClick={this.showRaces} className="btnShowInscription">Voir les courses</button>
                    <ul>
                        {races.map(function(race, i){
                            return <li key={i}><a href={'/resellers/registered/'+id+'/races/'+race.id+'/view'}>{race.name}</a></li>;
                        })}
                    </ul>
                </div>
            );
        }
    }
}

export default ResellersShowRaces